import React from "react";
import { Link } from "gatsby";
import ResponsiveArticleImage from "./responsive-article-image";
import CategoryNameComponent from "./presentation-components/category-name.component";

const CategoryItem = ({ article }) => {
    return (
        <div className="article-item">
            <Link to={`/article/${article.node.slug}`} className="article-link">
                <div className="article-item-content">
                    <div className="image-wrap">
                        <div className="gradient"> </div>
                        <ResponsiveArticleImage alt={article.node.title} formats={article.node.imageFormats}></ResponsiveArticleImage>
                        <CategoryNameComponent category={article.node.category}></CategoryNameComponent>
                    </div>
                    <div className="article-text">
                        <p className="article-title">
                            {article.node.title}
                        </p>
                        <p className="article-beginning">
                            {article.node.description}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CategoryItem;
