import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import CategoryItem from "../components/category-item";

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiArticle(
      filter: { category: { slug: { eq: $slug } } },
      sort: {fields: published_at, order: DESC}
    ) {
      edges {
        node {
          published_at
          slug
          title
          description
          imageSrc
          category {
            name
          }
          image {
            publicURL
            childImageSharp {
              fixed(width: 660) {
                src
              }
            }
          }
          imageFormats {
            id
            formatSize
            url
            width
          }
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
    }
  }
`;

const Category = ({ data }) => {
    const articlesList = data.articles.edges;
    const category = data.category.name;
    const seo = {
        metaTitle: category,
        metaDescription: `All ${category} articles`,
    };

    const [ active, setActive ] = useState(0);
    const [ hasMore, setMore ] = useState(articlesList.length > 2);
    const [ articles, addArticles ] = useState([...articlesList.slice(0, 2)]);

    const loadArticles = () => {
        const currentLength = articles.length;
        const more = currentLength < articlesList.length;
        const nextArticles = more ? articlesList.slice(currentLength, currentLength + 2) : [];
        setMore(more);
        addArticles([...articles, ...nextArticles]);
    };

    const handleScroll = () => {
        if ( !hasMore ) return;
        if ( window && ( window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500 ) ){
            loadArticles()
        }
    };

    const handleTouchEnd = (e) => {
        if (e.target.localName !== "a") {
            handleScroll();
        }
    };

    useEffect(() => {
        window && window.addEventListener('touchend', handleTouchEnd, {passive: true});
        window && window.addEventListener('scroll', handleScroll, {passive: true});
        window && window.addEventListener('resize', handleScroll, {passive: true});
        return () => {
            window && window.removeEventListener('touchend', handleTouchEnd);
            window && window.removeEventListener('scroll', handleScroll);
            window && window.removeEventListener('resize', handleScroll);
        };
    }, [articles, hasMore]);

    return (
        <Layout seo={seo}>
            <div className="page-wrapper">
                <div className="page-container">
                    <div className="category-article-list">
                        <h1>{category}</h1>
                        <div className="article-list">
                            {articles.map((article, i) => {
                                return (
                                    <CategoryItem index={i + 1} className={i + 1 === active ? "active" : "normal"} onMouseOver={e => setActive(i+1)}
                                        article={article}
                                        key={article.node.slug}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Category;
